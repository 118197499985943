<template>
  <div class="p32 p-t-32">
    <div class="tt df">
      <div class="suxian"></div>
      设置/重置密码
    </div>
    <div class="img">
      <img
        v-show="step === 1"
        src="../../../assets/images/resetPassword/step1.png"
        alt=""
      />
      <img
        v-show="step === 2"
        src="../../../assets/images/resetPassword/step2.png"
        alt=""
      />
    </div>
    <div class="reset-box">
      <div v-show="step === 1" class="step1">
        <div class="sms-wrapper">
          <div class="text">请输入手机号：</div>

          {{ phone | replacestar }}
        </div>
        <div class="sms-wrapper mt20">
          <div class="text">请输入验证码：</div>
          <el-input placeholder="请输入验证码" v-model="smsCode" clearable>
          </el-input>
          <button
            :disabled="disabledGetSmsCode"
            class="sms-code ml20"
            :class="disabledGetSmsCode ? 'disabled ml20' : ''"
            @click.stop="fetchCode"
          >
            {{ send }}
          </button>
        </div>

        <div class="next-step">
          <el-button @click="nextStepFn" type="success">下一步</el-button>
        </div>
      </div>
      <div v-show="step === 2" class="step2">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="140px"
          class="demo-ruleForm"
        >
          <el-form-item label="新的支付密码：" prop="newPwd">
            <el-input
              v-model="ruleForm.newPwd"
              show-password
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item
            style="margin-top:40px"
            label="再次输入密码："
            prop="confirmPwd"
          >
            <el-input
              v-model="ruleForm.confirmPwd"
              show-password
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class=" second-step">
              <el-button @click="backFn">放弃</el-button>
              <el-button @click="submit" type="success">确定</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/custom-base/custom-input/index.vue";
import { info } from "@/request/users/users";

import { YzApi, loginPhoneApi } from "@/request/Login/loginApi";
import { resetPasswordApi, checkSmsCodeApi } from "@/request/users/users";
import Base64 from '@/utils/base64'
export default {
  components: {
    CustomInput,
  },
  created() {
    this.getUserPhone();
  },

  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error("两次密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      phone: "",
      smsCode: "",
      disabled: false,
      send: "获取验证码",
      loading: false,
      step: 1,
      ruleForm: {
        newPwd: "",
        confirmPwd: "",
      },
      rules: {
        newPwd: [
          {
            required: true,
            message: "请输入密码",
            transform: (value) => value,
            trigger: "blur",
          },
          {
            type: "string",
            message: "请输入不包含空格的字符",
            trigger: "blur",
            transform(value) {
              if (value && value.indexOf(" ") === -1) {
                return value;
              } else {
                return false;
              }
            },
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              var passwordreg = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{8,16}$/;

              if (!passwordreg.test(value)) {
                callback(
                  new Error("请输入8-16位字母、数字、符号密码，至少包含两种")
                );
              } else {
                callback();
              }
            },
          },
        ],
        confirmPwd: [
          { required: true, message: "请确认密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 8 到 16 个字符",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur", required: true },
        ],
      },
    };
  },
  computed: {
    disabledGetSmsCode() {
      return this.disabled || !this.validatePhone();
    },
  },

  methods: {
    async getUserPhone() {
      const { code, data } = await info();
      if (code === 200) this.phone = data.phone;
    },
    backFn() {
      this.$confirm("确认放弃重置密码？", "提示")
        .then(() => {
          this.ruleForm = {
            newPwd: "",
            confirmPwd: "",
          };

          this.step = 1;
        })
        .catch(() => {});
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          console.log(this.confirmPwd);
          console.log();
          resetPasswordApi({
            payPassword: Base64.encode(this.ruleForm.confirmPwd),
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success("密码设置成功");
              this.ruleForm = {
                newPwd: "",
                confirmPwd: "",
              };
              this.step = 1;
            }
          });
        } else {
          return false;
        }
      });
    },
    async nextStepFn() {
      if (!this.smsCode) {
        this.$message.warning("请先输入验证码");
        return;
      }

      const { code } = await checkSmsCodeApi({
        phone: this.phone,
        smsCode: this.smsCode,
      });
      if (code === 200) {
        this.smsCode = "";
        this.step = 2;
      }
    },
    async handleSign() {
      this.validatePhone();
      this.loading = true;
      try {
        const { code, data } = await loginPhoneApi({
          smsCode: this.smsCode,
          phone: this.phone,
        });
        if (code === 200) {
          sessionStorage.setItem("token", data.token);
          this._clearForm();
          this.$router.push("/home");
        }
      } catch (error) {
        this.$message.error(error);
      } finally {
        this.loading = false;
      }
    },
    _clearForm() {
      this.phone = "";
      this.smsCode = "";
    },
    getCode() {
      let self = this;
      if (!self.phone) {
        self.$message.warning("请输入手机号");
        return;
      }
      if (self.disabled) return;
      self.disabled = true;
      let time = 60;
      timer && clearInterval(timer);
      const timer = setInterval(fun, 1000);
      function fun() {
        time--;
        if (time >= 0) {
          self.send = time + "s后刷新";
        } else if (time < 0) {
          self.send = "获取验证码";
          self.disabled = false;
          clearInterval(timer);
          time = 60;
        }
      }
    },
    async fetchCode() {
      this.validatePhone();
      const { code } = await YzApi({ telephone: this.phone });
      if (code === 200) {
        this.$message.success("短信发送成功");
        this.getCode();
      }
    },
    validatePhone() {
      const phoneReg = /^((\+|00)86)?1[3-9]\d{9}$/;
      return phoneReg.test(this.phone);
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-input {
    width: 250px;
  }
  .input-wrapper-content .focus[data-v-b2890fba] {
    height: 46px;
    line-height: 48px;
  }
}
.img {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}
.ml20 {
  margin-left: 20px;
}

.warning {
  position: absolute;
  right: -120px;
  font-size: 14px;
  color: #ff504e;
}
.next-step {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}
.second-step {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}
.reset-box {
  display: flex;
  justify-content: center;
}
.sms-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  .sms-code {
    border: 1px solid #26c487;
    padding: 11px 26px;
    color: #26c487;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    background: transparent;
  }
  .disabled {
    border: 1px solid #26c487;
    color: #adafad;
    cursor: not-allowed;
  }
}
.sign-button {
  margin: 26px auto;
  width: 100%;
  height: 50px;
  background: #26c487;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
}
</style>
